import React from 'react'
import "./header.css";
import Typewriter from 'typewriter-effect';

function Header() {
  return (
    <div className='header-container'>
        <div className='display-container'>
        <img src='/images/udit-logo.png' alt='logo' className='logo'/>
            <h1><Typewriter
              options={{
                loop: true,
              }}
              onInit={(typewriter) => {
                typewriter.typeString(`const name = Udit Pandya;`)
                  .pauseFor(2000)
                  .deleteAll()
                  .typeString(`Software Engineer<br>Specializing in backend development<br>DevOps, Automation & Cloud Enthusiast...`)
                  .pauseFor(3000)
                  .deleteAll()
                  .start();
              }}
            /></h1>
        </div>
    </div>
  )
}

export default Header;