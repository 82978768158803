module.exports = [
    {
        title: "AWS Solutions Architect (SAA-C03)",
        provider: "Amazon Web Services",
        expires: true,
        valid_from: "Dec 05, 2023 - Dec 05, 2026",
        link: "https://res.cloudinary.com/djzeu3p4n/image/upload/v1708299104/certificates/AWS_Certified_Solutions_Architect_-_Associate_certificate_low5yf.pdf"
    },
    {
        title: "AWS Certified Cloud Practitioner (CLP-C01)",
        provider: "Amazon Web Services",
        expires: true,
        valid_from: "March 05, 2022 - March 05, 2025",
        link: "https://res.cloudinary.com/djzeu3p4n/image/upload/v1672291280/certificates/AWS_Certified_Cloud_Practitioner_certificate_x3pa5z.pdf"
    },
    {
        title: "Kibana Fundamentals",
        provider: "Elastic",
        expires: false,
        link: "https://res.cloudinary.com/djzeu3p4n/image/upload/v1672291280/certificates/Kibana_Fundamentals_ososaz.pdf"
    },
    {
        title: "Spring Boot Microservice",
        provider: "LinkedIn Learning",
        expires: false,
        link: "https://res.cloudinary.com/djzeu3p4n/image/upload/v1672291280/certificates/Spring_Boot_gjommj.pdf"
    },
    {
        title: "NSE 1 Network Security Associate",
        provider: "Fortinet",
        expires: true,
        valid_from: "October 13, 2022 - October 13, 2024",
        link: "https://res.cloudinary.com/djzeu3p4n/image/upload/v1672291280/certificates/NSE_1_Certification_aaguyv.pdf"
    },
    {
        title: "NSE 2 Network Security Associate",
        provider: "Fortinet",
        expires: true,
        valid_from: "October 13, 2022 - October 13, 2024",
        link: "https://res.cloudinary.com/djzeu3p4n/image/upload/v1672291280/certificates/NSE_2_Certification_zfgaxk.pdf"
    },
    {
        title: "NSE 3 Network Security Associate",
        provider: "Fortinet",
        expires: true,
        valid_from: "October 18, 2022 - October 18, 2024",
        link: "https://res.cloudinary.com/djzeu3p4n/image/upload/v1672291280/certificates/NSE_3_Certification_kxw5s8.pdf"
    }
]