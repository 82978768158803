import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Masonry from '@mui/lab/Masonry';
import { StyledEngineProvider } from '@mui/material/styles';
import './skills.css';

const skillStack = require("../../utils/skills");

function Skills() {

    const [skills] = useState(skillStack);

  return (
    <div className='skill-container'>
        <StyledEngineProvider injectFirst>
            <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4}} spacing={3} defaultSpacing={3}>
            
                {skills.map(skill =>
                    <Paper elevation={5} variant='elevation' className='paper-container'>
                        <Typography className='title'>
                            {skill.title}
                        </Typography>
                        <Divider variant="middle" className='partition' />
                        <div className='chip-container'>
                            {
                                skill.keywords.map(key => {
                                    return (
                                        <Chip label={key} className='chip-label'/>
                                    )
                                })
                            }
                        </div>
                    </Paper>    
                )}
            </Masonry>
        </StyledEngineProvider>
    </div>
  )
}

export default Skills;