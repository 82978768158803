import React from 'react';
import styled from "styled-components";
import  { FaLinkedin } from "react-icons/fa";
import { BsGithub } from "react-icons/bs";
import { SiLeetcode } from "react-icons/si"
import { HiOutlineMail } from "react-icons/hi";
import "./footer.css";

function Footer() {
  const year = new Date().getFullYear();

  return (
    <Container>
      <div className='copyright'>
        &#169; {year} Udit Pandya.
      </div>
      <div className='connect'>
        <a href='https://www.linkedin.com/in/uditpandya/' target="_blank" rel="noreferrer" className='icon'>
          <FaLinkedin size='3em'/>
        </a>
        <a href='https://github.com/ud17' target='_blank' rel='noreferrer' className='icon'>
          <BsGithub size='3em'/>
        </a>
        <a href='mailto:uditpandya.dev@gmail.com' target='_blank' rel='noreferrer' className='icon'>
              <HiOutlineMail size='3em'/>
        </a>
        <a href='https://leetcode.com/ud17/' target='_blank' rel='noreferrer' className='icon'>
          <SiLeetcode size='3em'/>
        </a>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 3% 2%;
  color: #41a344;
  border-radius: 15px 15px 0px 0px;
`

export default Footer;