import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from "styled-components";
import Skills from "../components/skills/Skills";
import Certification from "../components/certifications/Certification";

const HOME = require("../utils/home");

function Home() {

  const [isCertificationVisible, setCertificationVisibility] = useState(false);

  const handleCertificationToggle = async () => {
    setCertificationVisibility(!isCertificationVisible);
  }

  return (
    <Container>
      <Title>
        let &#123; introduction &#125; = self;
      </Title>
      <Title>
        console.log( introduction );
      </Title>
      {HOME.introduction.split('|').map(info => <Introduction>{info}</Introduction>)}
      <Title>
        let &#123; skills &#125; = self;
      </Title>
      <Title>
        console.log( skills );
      </Title>
      <Skills />
      <Title>
        let &#123; certifications &#125; = self;
      </Title>
      <Card elevation={5} variant='elevation'>
        <Info>
          console.log( certifications );
          <Toggle variant="text" onClick={handleCertificationToggle}>{isCertificationVisible ? 'HIDE' : 'RUN'}</Toggle>
        </Info>
      </Card>
      {isCertificationVisible ? <Certification /> : ''}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0% 1%;
`

const Card = styled(Paper)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #3d3d3d;
  color: beige;
  padding: 1%;
  font-family: 'Source Code Pro';
`;

const Title = styled(Typography)`
  display: flex;
  justify-content: center;
  color: #41a344;
  padding: 1% 0%;
  font-family: 'Source Code Pro';
  font-size: 1.5rem;
`

const Introduction = styled(Typography)`
  display: flex;
  flex-wrap : wrap;
  text-align: justify;
  font-family: 'Source Code Pro';
  color: beige;
  font-size: 1.2rem;
`

const Info = styled(Typography)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  text-align: justify;
  font-family: 'Source Code Pro';
`

const Toggle = styled(Button)`
  display: flex;
  justify-content: center;
  color: #41a344;
  font-family: 'Source Code Pro';
  border: none;
`

export default Home;