import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Project from '../../pages/Project';
import Home from '../../pages/Home';
import Profile from '../../pages/Profile';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import SchoolIcon from '@mui/icons-material/School';
import { StyledEngineProvider } from '@mui/material/styles';
import './navbar.css';

const indicatorStyle = {
  sx: {
    background: 'beige',
  }
}

function Navbar() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <StyledEngineProvider injectFirst>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} TabIndicatorProps={indicatorStyle} centered variant='fullWidth'>
              <Tab label="<Home />" value="1" className='tab'/>
              <Tab label="let My_WORK;" value="2" className='tab text-lowercase'/>
              <Tab label={<div><ImportantDevicesIcon /> <SchoolIcon/></div>} value="3" className='tab'/>
            </TabList>
          </Box>
          <TabPanel value="1"><Home/></TabPanel>
          <TabPanel value="2"><Project/></TabPanel>
          <TabPanel value="3"><Profile/></TabPanel>
        </TabContext>
      </StyledEngineProvider>
    </Box>
  );
}

export default Navbar;