import React from 'react'
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import GitHubIcon from '@mui/icons-material/GitHub';
import SyncIcon from '@mui/icons-material/Sync';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CodeIcon from '@mui/icons-material/Code';
import { StyledEngineProvider } from '@mui/material/styles';
import './card.css';

function Card({project}) {

  return (
    <div className='card-contiainer'>
        <StyledEngineProvider injectFirst>
            <Paper elevation={5} variant='elevation' className='paper-container'>
                <Typography className='title'>
                    {project.title}
                </Typography>
                <Typography className='stack'>
                    {project.stack}
                    <Link href={project.github} target='_blank' underline='none'>
                        <GitHubIcon sx={'margin: 0px 10px; color: #41a344'}/>
                    </Link>
                    {
                        project.hosting && <Link href={project.hosting} target='_blank' underline='none'>
                            <CloudDoneIcon sx={'color: #41a344'}/>
                        </Link>
                    } 
                    {
                        project.leetcode && <Link href={project.leetcode} target='_blank' underline='none'>
                            <CodeIcon sx={'color: #41a344'}/>
                        </Link>
                    }
                </Typography>
                {
                        project.in_progress && <Chip className='chip-label' label='In Progress...' avatar={<SyncIcon />}/>
                }
                <Divider variant="middle" className='partition' />
                <Typography className='description'>
                    {project.desc}
                </Typography>
                <Divider variant="middle" className='partition' />
                <div className='chip-container'>
                    {
                        project.keywords.map(key => {
                            return (
                                <Chip label={key} className='chip-label'/>
                            )
                        })
                    }
                </div>
            </Paper>
        </StyledEngineProvider>
    </div>
  )
}

export default Card