import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Masonry from '@mui/lab/Masonry';
import Link from '@mui/material/Link';
import { StyledEngineProvider } from '@mui/material/styles';
import './certification.css';

const certificates = require("../../utils/certificates");

function Certification() {

    return (
        <div className='certification-container'>
            <StyledEngineProvider injectFirst>
                <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4}} spacing={3} defaultSpacing={3} className='grid'>
                    {certificates.map(certificate =>
                        <Paper elevation={5} variant='elevation' className='card-container'>
                            <Link href={certificate.link} target='_blank' underline='none'>
                                <Typography className='data'>
                                    {certificate.provider}
                                </Typography>
                                <Typography className='expiry'>
                                    {certificate.expires ? certificate.valid_from : <i>doesn't expire</i>}
                                </Typography>
                                <Divider variant="middle" className='partition' />
                                <Typography className='data'>
                                    {certificate.title}
                                </Typography>
                            </Link>
                        </Paper>    
                    )}
                </Masonry>
            </StyledEngineProvider>
        </div>
    )
}

export default Certification