import React from 'react';
import AboutMe from "../components/aboutme/AboutMe";
import styled from "styled-components";

function Profile() {
  return (
    <Container>
      <AboutMe />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`

export default Profile;