module.exports = [
    {
        designation: "Software Engineer SIEM",
        company: "Access 2 Networks (A2N), Mississauga, CA",
        duration: "Oct 2022 - Present",
        responsibilities: [
            "•	Lead implementation of a AWS CloudTrail log ingestion pipeline storing data in S3. Orchestrate real-time SNS notifications, feeding a high-throughput SQS queue. Enable FortiSIEM polling for streamlined log retrieval.",
            "•	Leverage Elasticsearch to develop a real-time log analysis and monitoring SIEM solution using Node.js and an observability and reporting Flask solution to provide actionable insights and reducing incident response times for improved system reliability.",
            "•	Streamline the release process by configuring GitLab CI/CD Pipeline enabling consistent automated deployments of the application updates while maintaining high service availability and reducing risk of downtime.",
            "•	Successfully reduced the number of defects and improved code maintainability by adopting Test-Driven Development principles and writing Unit Tests leading to a 50% reduction in post-release issues.",
            "•	Designed an event-driven fully automated report generation solution utilizing data processing tools, scripting languages like JavaScript and Python along with Mongodb and Elasticsearch on the backend to meet specific business requirements.",
        ]
    },
    {
        designation: "Software Developer Intern",
        company: "PAQT, Toronto, CA",
        duration: "Jan 2023 - Apr 2023",
        responsibilities: [
            "• Led the implementation of Stripe payment gateway and webhooks for real-time user updates to synchronize application database.",
            "• Led a team of 3 developers, conducted weekly client meetings to update stakeholders about the progress leading to successful delivery of 5+ features in under 3 months."
        ]
    },
    {
        designation: "Software Developer",
        company: "Xcitech Technologies, Vadodara, IN",
        duration: "Oct 2020 - Oct 2021",
        responsibilities: [
            "•	Implemented a scalable and secure backend architecture, ensuring data privacy and delivering seamless user experience.",
            "•	Designed robust RESTful APIs using Express.js framework while implementing JWT based authentication & authorization.",
            "•	Managed and Led a cross-functional team of engineers throughout the entire software development lifecycle (SDLC).",
            "•	Ensured timely delivery of solutions & deliverables following Agile Scrum methodology and initiating stand-up meeting."
        ]
    }
]