import './App.css';
import Dashboard from './components/dashboard/Dashboard';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Udit Pandya</title>
        <meta name="description" content="Portfolio website by Udit Pandya" />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      <Dashboard />
    </div>
  );
}

export default App;
