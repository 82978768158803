module.exports = [
    {
        program: "Information Technology Solutions",
        college: "The Humber Institute of Technology and Advanced Learning",
        duration: "Ontario graduate certificate (Sept, 2021 - Apr, 2023)",
        scholarship: "•	Recipient of Barrett Family Foundation Entrance Scholarship"
    },
    {
        program: "Information Technology",
        college: "Gujarat Technological University",
        duration: "Bachelor's Degree (Aug, 2016 - Aug, 2020)"
    }
]