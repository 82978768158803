import React from 'react'
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import "./dashboard.css";

function Dasboard() {
  return (
    <div className='dashboard-container'>
        <Header />
        <Navbar />
        <Footer />
    </div>
  )
}

export default Dasboard