import React from 'react'
//import Grid from '@mui/material/Grid';
import Card from '../components/card/Card';
import Masonry from '@mui/lab/Masonry';
const data = require("../utils/projects");

function Project() {
  return (
    <div>
      {/* <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 4, md: 5 }}>
        {
            data.getArray().map(project => {
                return (
                  <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                    <Card project={project}/>
                  </Grid>
                )
            })
        }
      </Grid> */}
      <Masonry columns={{ xs: 1, sm: 1, md: 2, lg: 3}} spacing={3}>
        {
            data.getArray().map(project => {
                return (
                  // <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                    
                  // </Grid>
                  <Card project={project}/>
                )
            })
        }
      </Masonry>
    </div>
  )
}

export default Project;