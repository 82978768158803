module.exports = [
    {
        title: 'Programming Languages',
        keywords: [
            'Java',
            'Go',
            'JavaScript',
            'TypeScript',
            'Kotlin',
            'Python',
            'SQL',
            'HTML',
            'CSS',
            'Bash/Shell Scripting'
        ]
    }
    ,{
        title: 'Libraries / Framework',
        keywords: [
            'Node.js',
            'Express.js',
            'Nest.js',
            'React.js',
            'Angular',
            'Redux Toolkit',
            'Spring Boot',
            'J2EE',
            'Material UI',
            'Bootstrap',
            'Android Native',
            'Retrofit 2'
        ]
    },
    {
        title: 'Cloud Technology & Tools',
        keywords: [
            'AWS',
            'Docker',
            'Digital Ocean',
            'Netlify',
            'CI/CD Pipeline'
        ]
    },
    {
        title: 'Databases',
        keywords: [
            'MongoDB',
            'PostgreSQL',
            'ElasticSearch',
            'MySQL',
            'Oracle',
            'MSSQL',
        ]
    },
    {
        title: 'Development Tools',
        keywords: [
            'MongoDB Atlas',
            'MongoDB Compass',
            'Kibana',
            'Logstash',
            'Postman',
            'pgAdmin4',
            'Workbench',
            'Jira',
            'IntelliJ IDEA',
            'Android Studio',
            'VS Code',
            'Eclipse'
        ]
    },
    {
        title: 'Software Methodologies',
        keywords: [
            'Agile methodology',
            'Object-oriented Design',
            'SOLID',
            'SDLC',
            'OOPs'
        ]
    },
    {
        title: 'Testing & Version Control',
        keywords: [
            'Unit Tests',
            'Test Driven Development(TDD)',
            'Git',
            'GitLab',
            'GitHub',
            'Bitbucket',
            'TortoiseSVN'
        ]
    },
    // {
    //     title: 'Professional',
    //     keywords: [
    //         'Leadership',
    //         'Problem-solving',
    //         'Critical thinking',
    //         'Proactive',
    //         'Resilience',
    //         'Adaptability',
    //         'Flexibility'
    //     ]
    // }
]